<template>
    <div>
        <div v-if="!input || loading">
            <span class="text-black"> Cargando .. </span>
        </div>
        <div
            id="barcode-viewer-container"
            class="d-flex justify-content-center"
        ></div>
    </div>
</template>

<script>
import JsBarcode from "jsbarcode";

export default {
    name: "BarcodeViewer",
    props: {
        input: {
            type: String,
            default: "0",
            required: true
        },
        displayValue: {
            type: Boolean,
            default: false,
            required: false
        }
    },

    data: () => ({ loading: false }),
    computed: {},
    watch: {
        input(value) {
            this.sendBarcode(value);
        }
    },
    mounted() {
        this.loading = true;
        this.sendBarcode(this.input);
        this.loading = false;
    },
    methods: {
        sendBarcode(value) {
            const divDOM = document.getElementById("barcode-viewer-container");
            const svg = document.createElementNS(
                "http://www.w3.org/2000/svg",
                "svg"
            );
            svg.classList.add("barcode-class");
            divDOM.appendChild(svg);
            JsBarcode(".barcode-class", value, {
                height: 70,
                width: 2.35,
                margin: 0,
                fontSize: 40,
                displayValue: this.displayValue
            });
        }
    }
};
</script>
