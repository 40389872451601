import html2canvas from "html2canvas";

export const downLoadImage = (imageStr, filename = "download") => {
    const link = document.createElement("a");
    link.href = imageStr;
    link.download = `${filename}.png`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};

export const openImage = (image = {}, title = "Imagén") => {
    const iframe2 = `<!DOCTYPE html>
    <html lang="es">
    <head>
      <meta charset="UTF-8">
      <meta name="viewport" content="width=device-width, initial-scale=1.0">
      <title>${title}</title>
      <style>
    
        .main {
          display: flex;
          justify-content: center; 
        }
        .container {
          border: 2px solid black;
          padding: 10px;
        }
      </style>
    </head>
    <body>
    
    <div class="main">
        <div class="container">
            <img width="${image.width}" height="${image.height}" src="${image.image}"/>
        </div>
    </div>
    </body>
    </html>`;
    const x = window.open();
    x.document.open();
    x.document.write(iframe2);
    x.document.close();
};

export const elementToImage = async (element) => {
    const canvas = await html2canvas(element, {});
    const image = canvas.toDataURL("image/png");
    return { image, width: canvas.width, height: canvas.height };
};
